import { stringify } from 'querystring';
import {
  Identifier,
  PaginationPayload,
  SortPayload,
  fetchUtils,
  DataProvider,
  HttpError,
  FilterPayload,
} from 'ra-core';
import { createOptionsFromToken } from './authProvider';

export {
  default as tokenAuthProvider,
  fetchJsonWithAuthToken
} from './authProvider';


const fetchNonJson = (url: any, options: any = {}) => {
  options = Object.assign(createOptionsFromToken(), options)
  const requestHeaders = fetchUtils.createHeadersFromOptions(options);

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }))
    )
    .then(({ status, statusText, headers, body }) => {

      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError(
            statusText,
            status,
            body
          )
        );
      }
      return Promise.resolve({ status, headers, body });
    });
};


export const resourceMapping: { [resourceName: string]: string } = {
  'teams': 'account/teams',
  'all_teams': 'account/teams/all_teams',
  'consumer_teams': 'account/teams/consumer_teams',
  'consumer_statistics': 'statistics/consumer',
  'consumer_services': 'orcabase/consumer/services',
  'user_invitations': 'account/user/invitations',
  'team_invitations': 'account/team/invitations',
  'user_join_requests': 'account/user/join_requests',
  'team_join_requests': 'account/team/join_requests',
  'user_memberships': 'account/user/memberships',
  'team_memberships': 'account/team/memberships',
  'search_teams': 'account/teams/search_team',
  'services': 'orcabase/serviceowner/services',
  'serviceowner_teams': 'account/teams/serviceowner_teams',
  'service_schemas': 'orcabase/serviceowner/schemas',
  'serviceowner_service_configs': 'orcabase/serviceowner/service_configs',
  'consumer_service_configs': 'orcabase/consumer/service_configs',
  'serviceowner_statistics': 'statistics/serviceowner',
  'dependant_services': 'orcabase/serviceowner/services/dependant',
  'browse_services': 'orcabase/services',
  'submissions': 'orcabase/consumer/submissions',
  'service_items': 'orcabase/consumer/service_items',
  'dependant_service_items': 'orcabase/serviceowner/service_items/dependant',
  'owner_service_items': 'orcabase/serviceowner/service_items',
  'applications': 'orcabase/consumer/applications',
  'owner_applications': 'orcabase/serviceowner/applications',
  'change_instances': 'orcabase/consumer/change_instances',
  'owner_change_instances': 'orcabase/serviceowner/change_instances',
  'serviceowner_deployed_items': 'orcabase/serviceowner/deployed_items',
  'consumer_deployed_items': 'orcabase/consumer/deployed_items',
  'users': 'account/users',
  'apikeys': 'account/apikeys',
  'webhooks': 'external/serviceowner/webhooks',
  'webhook_responses': 'external/serviceowner/webhook_responses',
  'serviceowner_healthchecks': 'external/serviceowner/healthchecks',
  'consumer_healthchecks': 'external/consumer/healthchecks',
  'serviceowner_healthcheck_responses': 'external/serviceowner/healthcheck_responses',
  'consumer_healthcheck_responses': 'external/consumer/healthcheck_responses',
  'serviceowner_ai_validators': 'external/serviceowner/ai_validators',
  'serviceowner_ai_validator_responses': 'external/serviceowner/ai_validator_responses',
  'serviceowner_charges': 'marketplace/serviceowner/charges',
  'consumer_charges': 'marketplace/consumer/charges',
  'general_settings': 'core/general_settings',
  'llm_models': 'external/llm_models',
  'groups': 'account/groups',
  'team_groups': 'account/team_groups',
  'license': 'license',
  'login_google': 'login/google',
  'search': 'search'
}

const getPaginationQuery = (pagination: PaginationPayload) => {
  return {
    offset: pagination.page * pagination.perPage - pagination.perPage,
    limit: pagination.perPage,
  };
};

const getFilterQuery = (filter: FilterPayload) => {
  const { search: search, ...otherSearchParams } = filter;
  return {
    ...otherSearchParams,
    search,
  };
};

export const getOrderingQuery = (sort: SortPayload) => {
  const { field, order } = sort;
  return {
    ordering: `${order === 'ASC' ? '' : '-'}${field}`,
  };
};

export default (
  apiUrl: String | undefined,
  httpClient: Function = fetchUtils.fetchJson
): DataProvider => {
  const getOneJson = (resource: string, id: Identifier) =>
    httpClient(`${apiUrl}/${resourceMapping[resource]}/${id}/`).then(
      (response: Response) => response.json
    );
  return {
    getList: async (resource, params) => {
      const query = {
        ...getFilterQuery(params.filter),
        ...getPaginationQuery(params.pagination),
        ...getOrderingQuery(params.sort),
      };
      const url = `${apiUrl}/${resourceMapping[resource]}/?${stringify(query)}`;
      const { json } = await httpClient(url);

      let results = json.results.map(function (ele: any) {
        return { ...ele, id: ele.id}
      })
      return {
        data: results,
        total: json.count,
      };
    },
    getFilterOptions: async (resource: string, params: any) => {
      const query = {
        ...getFilterQuery(params.filter),
        ...getPaginationQuery(params.pagination),
        ...getOrderingQuery(params.sort),
      };
      const url = `${apiUrl}/${resourceMapping[resource]}/filters/?${stringify(query)}`;
      const { json } = await httpClient(url);
      return { data: json };
    },
    get: async (resource: string, params: any) => {
      const url = `${apiUrl}/${resourceMapping[resource]}/`;
      const { json } = await httpClient(url);
      return {
        data: json
      }
    },

    getOne: async (resource, params) => {
      const data = await getOneJson(resource, params.id);
      return {
        data,
      };
    },

    getMany: (resource, params) => {
      return Promise.all(
        params.ids.map(id => getOneJson(resourceMapping[resource], id))
      ).then(data => ({ data }));
    },

    getManyReference: async (resource, params) => {
      const query = {
        ...getFilterQuery(params.filter),
        ...getPaginationQuery(params.pagination),
        ...getOrderingQuery(params.sort),
        [params.target]: params.id,
      };
      const url = `${apiUrl}/${resourceMapping[resource]}/?${stringify(query)}`;

      const { json } = await httpClient(url);
      return {
        data: json.results,
        total: json.count,
      };
    },
    query: async (resource: string, params: any) => {
      const { json } = await httpClient(`${apiUrl}/${resourceMapping[resource]}/`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      });
      const data = { ...json };
      return {
        data: data,
      };
    },
    getAction: async (resource: string, params: any) => {
      let query = {}
      if (params.filter) {
        query = {
          ...getFilterQuery(params.filter),
        };
      }
      const { json } = await httpClient(`${apiUrl}/${resourceMapping[resource]}/${params.id ? params.id + '/' : ''}${params.verb}/?${stringify(query)}`);
      const data = { ...json };
      return {
        data: data,
      };
    },
    getActionNonJson: async (resource: string, params: any) => {
      let query = {}
      if (params.filter) {
        query = {
          ...getFilterQuery(params.filter),
        };
      }
      const { body } = await fetchNonJson(`${apiUrl}/${resourceMapping[resource]}/${params.id}/${params.verb}?${stringify(query)}`);
      return {
        data: body,
      };
    },
    update: async (resource, params) => {
      const { json } = await httpClient(`${apiUrl}/${resourceMapping[resource]}/${params.id}/`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      });
      return { data: json };
    },

    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resourceMapping[resource]}/${id}/`, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
          })
        )
      ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    create: async (resource, params: any) => {
      const { json } = await httpClient(`${apiUrl}/${resourceMapping[resource]}/`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      });
      const data = params.idField ? { ...json, id: json[params.idField] } : { ...json };
      return {
        data: data,
      };
    },
    createAction: async (resource: string, params: any) => {
      const { json } = await httpClient(`${apiUrl}/${resourceMapping[resource]}/${params.id ? params.id + '/' : ''}${params.verb}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      });
      const data = params.idField ? { ...json, id: json[params.idField] } : { ...json };
      return {
        data: data,
      };
    },
    delete: (resource, params) =>
      httpClient(`${apiUrl}/${resourceMapping[resource]}/${params.id}/`, {
        method: 'DELETE',
      }).then(() => ({ data: params.previousData })),

    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resourceMapping[resource]}/${id}/`, {
            method: 'DELETE',
          })
        )
      ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
  };
};
