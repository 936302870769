import React, {useState} from "react";
import {useNotify} from "react-admin";
import {Button, Collapse, Divider, Modal, Radio, Space, Tag} from "antd";
import {getTagForEvaluation} from "@src/components/Common/Tags";
import Editor from "@monaco-editor/react";
import EvaluationRowPanel from "@src/components/AIValidators/SubComponents/EvaluationRow";
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined} from "@ant-design/icons";

const { Panel } = Collapse;
const { confirm } = Modal;

interface EvaluationInterface {
    value?: any,
    onChange?: any,
    evaluationResults: any
}


const EvaluationList = ({value = [], onChange, evaluationResults}: EvaluationInterface) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [jsonInput, setJsonInput] = useState<any>("");
    const [isValid, setIsValid] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number|null>(null);
    const notify = useNotify()

    const showEditModal = (index: number) => {
        setJsonInput(JSON.stringify(value[index].declaration, null, 4));
        setIsValid(value[index].is_valid);
        setSelectedIndex(index);
        setIsModalVisible(true);
    }

    const handleOk = () => {
        try {
            const newEntry = {declaration: JSON.parse(jsonInput), is_valid: isValid, is_new: true};
            let updatedEvaluation;
            if (selectedIndex == null)
                updatedEvaluation = [...value, newEntry];
            else {
                updatedEvaluation = [...value];
                updatedEvaluation[selectedIndex] = newEntry;
            }

            onChange(updatedEvaluation);
            setIsModalVisible(false);
            setJsonInput('');
            setIsValid(false);
            setSelectedIndex(null);

        }  catch (e) {
            console.log(e)
            notify("Invalid JSON", "error")
        }

    };
    const handleCancel = () => {
        setIsModalVisible(false)
        setSelectedIndex(null)
    }

    const handleDelete = (index: any) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete the test case?',
            onOk() {
                // @ts-ignore
                const updatedEvaluation = value.filter((_, i) => i !== index);
                onChange(updatedEvaluation);
                // @ts-ignore
                evaluationResults["results"] = evaluationResults["results"].filter((_, i) => i !== index)
                return
            },
        });
    };

    return (
        <>
            <Collapse defaultActiveKey={[]} style={{ marginRight: '10px' }} >
                <Panel header={<><b style={{marginRight:"5px"}}>Evaluation  </b> {getTagForEvaluation(evaluationResults)}</>} key={0}>
                    <Space direction={"vertical"} style={{width: "100%"}}>
                        <Modal
                            title="Add Evaluation"
                            visible={isModalVisible}
                            closable={false}
                            onOk={handleOk}
                            onCancel={handleCancel}
                        >
                            <Editor
                                value={jsonInput}
                                height="50vh"
                                defaultLanguage="json"
                                options={{
                                    automaticLayout: true,
                                    autoIndent: "full",
                                    formatOnPaste: true,
                                    formatOnType: true
                                }}
                                onChange={(value) => setJsonInput(value)}
                            />
                            Expected Response:
                            <Radio.Group
                                value={isValid}
                                onChange={(e) => setIsValid(e.target.value)}
                                style={{ marginTop: '16px', marginLeft: "10px" }}
                            >
                                <Radio value={true}><Tag color="#69c0ff">APPROVED</Tag></Radio>
                                <Radio value={false}><Tag color="orange">REJECTED</Tag></Radio>
                            </Radio.Group>
                        </Modal>
                        <Collapse>
                            {
                                value.map((item: any, index: number) => (
                                    <Panel
                                        key={index}
                                        header={
                                            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                                {"Test "+(index+1)}
                                            </div>
                                        }
                                        extra={
                                            <Space direction={"horizontal"}>
                                                <Button type="primary" size={"small"}  className={"submission-builder-del-btn"} style={{ marginLeft: '8px' }} icon={<EditOutlined />} onClick={() => showEditModal(index)}/>
                                                <Button danger type="primary" size={"small"}  className={"submission-builder-del-btn"} style={{ marginLeft: '8px' }} icon={<DeleteOutlined />} onClick={() => handleDelete(index)}/>
                                            </Space>
                                        }
                                    >
                                        <EvaluationRowPanel index={index} item={item} evaluationResults={evaluationResults}/>
                                    </Panel>
                                ))
                            }
                        </Collapse>
                        <div style={{ width: "100%"}} >
                            <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ float: 'right' }}>Add a new test case</Button>
                        </div>
                    </Space>
                </Panel>
            </Collapse>
            <Divider />
        </>
    );
}

export default EvaluationList;